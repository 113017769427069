import React from "react"
import { Link } from "gatsby"
import Container from "layout/Container"
import Collapsible from "elements/Collapsible"
import SEO from "layout/SEO"
import classNames from "classnames"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserMd } from "@fortawesome/free-solid-svg-icons"
import gmeet from "../../../static/images/pages/gmeet_guide--logo.png"
import mute from "../../../static/images/pages/gmeet_guide--mute.png"
import calendar from "../../../static/images/pages/gmeet_guide--calendar.png"

const GoogleMeetGuide = () => {
  return (
    <Container isCentered>
      <SEO title="GMeet Guide" />
      <h2>
        <img src={gmeet} alt="Google Meet Guide" width="6%" /> Google Meet Guide
      </h2>
      <hr />
      <div className="content">
        <p>
          You can use Google Meet to view your upcoming teleconsults and meet
          virtually with your mental health professional.
        </p>
        <div className="message has-background-light">
          <div className="message-body">
            No installation is required as it’s conveniently accessible from
            your browser via
            <a
              href="https://meet.google.com"
              className="has-text-primary has-text-weight-bold"
            >
              {" "}
              meet.google.com{" "}
            </a>
            and is built into all Google Calendar events.
          </div>
        </div>
        <ol className="content">
          <li>
            Once logged into your Google account, going to
            <a
              href="https://calendar.google.com"
              className="has-text-primary has-text-weight-bold"
            >
              {" "}
              calendar.google.com{" "}
            </a>
            will show you upcoming teleconsults.
          </li>
          <li>
            Click the event and you will see an option to{" "}
            <strong>Join with Google Meet.</strong>
          </li>
        </ol>
        <div className="has-text-centered">
          <img src={calendar} alt="Google Calendar Invite" width="50%" />
        </div>
      </div>
      <h2 className="mt-4">
        <img src={gmeet} alt="Google Meet Guide" width="6%" /> Having audio or
        video issues?
      </h2>
      <hr />
      <div className="content">
        <h4>ACCESS TO MY MICROPHONE OR CAMERA WAS DENIED</h4>
        <p>
          Google Meet needs permission to use your camera and microphone in
          Chrome. You will be
          <a
            href="https://support.google.com/chrome/answer/2693767"
            className="has-text-primary has-text-weight-bold"
          >
            asked to allow access
          </a>
          the first time you join a Meet video call.
        </p>
        <h4 className="mt-4 mb-2">PEOPLE CAN’T SEE ME IN THE MEETING</h4>
        <ol className="content">
          <li>
            Check that your computer’s camera is connected, turned on, and
            pointing towards you unobstructed.
          </li>
          <li>
            Check if your camera functions in other apps, like FaceTime in macOS
            or the Camera app in Windows.
          </li>
          <li>
            Close any other application that might be using the camera, then
            reload Google Meet.
          </li>
          <li>Restart your computer or your Google Chrome browser.</li>
        </ol>
        <div className="message has-background-light">
          <div className="message-body">
            On computers using Chrome, you must allow the browser to access your
            camera.
          </div>
        </div>
        <h4 className="mt-4 mb-2">PEOPLE CAN’T HEAR ME IN THE MEETING</h4>
        <ul>
          <li className="is-vcentered">
            Make sure that your mic is on.{" "}
            <img src={mute} alt="Google Meet Mute Icon" width="12%" />
          </li>
          <li>Make sure your mic wasn’t muted when you joined the meeting.</li>
        </ul>
        <h4 className="mt-4">OTHER ISSUES</h4>
        <p>
          If you encounter other issues, please refer to
          <a
            href="https://support.google.com/meet/answer/7380413?hl=en"
            className="has-text-primary has-text-weight-bold"
            target="_blank"
            rel="noopener noreferrer"
          >
            this guide.
          </a>
        </p>
      </div>
    </Container>
  )
}

export default GoogleMeetGuide
